import React from "react"
import Button from "../Button"
import { HeroesContainer, LandingContainer, VideoWrapper } from "./style"
import ReactPlayer from "react-player"
import Hero, { CarouselItem } from "../Layout/Hero"
import Content from "../Layout/Content"
import bgImage from "../../../static/video/home.mp4"
import SEOImage from "../../../static/img/videoBg.png"
import { Heading } from "../Layout/Hero/style"
import SEO from "../SEO"
import { navigate } from "gatsby"

const index = () => {
  const carouselSlide: CarouselItem[] = [
    {
      backgroundImg: "WK_1O_-5jgw",
      isVideo: true,
      subheading: {
        mobileFontAlign: "center",
        desktopFontAlign: "center",
        mobileMargin: "150px 0px 70px 0px",
        desktopMargin: "300px 0px 70px 0px",
        maxWidth: "1092px",
        text:
          "Enabling sustainable production of everyday items, " +
          "so that helping the environment becomes an everyday thing.",
      },
      btnList: [
        {
          label: "What We Do",
          mobileFontSize: "18px",
          desktopFontSize: "24px",
          onClick: () => navigate("/solutions"),
        },
        {
          label: "Why Hemp?",
          mobileFontSize: "18px",
          desktopFontSize: "24px",
          onClick: () => navigate("/hemp"),
          desktopMargin: "60px",
          mobileMargin: "40px",
        },
      ],
    },
  ]

  return (
    <>
      <SEO
        title="Tangho - We Can Be Heroes"
        description="Enabling sustainable production of everyday items, so that helping the environment becomes an everyday thing."
        image={SEOImage}
        url="/"
      />
      <Hero carouselItems={carouselSlide} mobileHeight={548} />
    </>
  )
}

export default index
